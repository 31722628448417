<template>
  <div class="about row">
    <vxe-grid ref="xGrid" v-bind="gridOptions">

      <template #status_switch="{ row }">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" role="switch" :data-id="row.id" :checked="row.status"
            @change="change($event, row)" />
        </div>
      </template>
      <template #toolbar_buttons>

      </template>
    </vxe-grid>

  </div>
</template>
<script>
import {
  userList,
  userUpdate,
} from "@/requests/api";
import { VXETable } from "vxe-table";
import { ref } from 'vue'
const xGrid = ref()
import XEUtils from "xe-utils";
export default {
  name: "",
  mounted() {
  },
  created() {

  },
  data() {
    return {
      selectRow: true,
      menus: [],
      showEdit: false,
      loadding: false,
      formData: {
        nickName: "",
        phone: '',
        status: 1
      },
      formRules: {
        name: [
          { required: true, message: "请输入名称" },
          { min: 2, max: 6, message: "长度在 2 到 6 个字符" },
        ],
      },
      gridOptions: {
        border: true,
        showHeaderOverflow: true,
        showOverflow: true,
        keepSource: true,
        id: "menu_list_table",
        height: 700,
        rowConfig: {
          keyField: "id",
          isHover: true,
        },
        columnConfig: {
          resizable: true,
        },
        customConfig: {
          storage: true,
          // checkMethod({ column }) {
          //   if (["create_time", "update_time"].includes(column.field)) {
          //     return false;
          //   }
          //   return true;
          // },
        },
        printConfig: {
          columns: [
            { field: "id" },
            { field: "nickName" },
            { field: "phone" },
            { field: "status" },
          ],
        },
        sortConfig: {
          trigger: "cell",
          remote: true,
        },
        filterConfig: {
          remote: true,
        },
        pagerConfig: {
          pageSize: 10,
          pageSizes: [5, 10, 15, 20, 50, 100, 200, 500, 1000],
        },
        formConfig: {
          titleWidth: 100,
          titleAlign: "right",
          items: [
            {
              field: "nickName",
              title: "名称",
              titlePrefix: {
                message: "名称检索",
                icon: "vxe-icon-question-circle-fill",
              },
              itemRender: {
                name: "$input",
                props: { placeholder: "请输入名称" },
              },
            },
            {
              field: "phone",
              title: "手机号",
              itemRender: {
                name: "$input",
                props: { placeholder: "请输入手机号" },
              },
            },

            {
              align: "center",
              // collapseNode: true,
              itemRender: {
                name: "$buttons",
                children: [
                  {
                    props: {
                      type: "submit",
                      content: "搜索",
                      status: "primary",
                    },
                  },
                  { props: { type: "reset", content: "清空" } },
                ],
              },
            },
          ],
        },
        toolbarConfig: {
          slots: {
            buttons: "toolbar_buttons",
          },
          refresh: true,
          import: false,
          export: true,
          print: true,
          zoom: true,
          custom: true,
        },
        proxyConfig: {
          seq: true, // 启用动态序号代理，每一页的序号会根据当前页数变化
          sort: true, // 启用排序代理，当点击排序时会自动触发 query 行为
          filter: true, // 启用筛选代理，当点击筛选时会自动触发 query 行为
          form: true, // 启用表单代理，当点击表单提交按钮时会自动触发 reload 行为
          // 对应响应结果 { result: [], page: { total: 100 } }
          props: {
            result: "data", // 配置响应结果列表字段
            total: "total", // 配置响应结果总页数字段
          },
          // 只接收Promise，具体实现自由发挥
          ajax: {
            // 当点击工具栏查询按钮或者手动提交指令 query或reload 时会被触发
            query: ({ page, sorts, filters, form }) => {
              const queryParams = Object.assign({}, form);
              // 处理排序条件
              const firstSort = sorts[0];
              if (firstSort) {
                queryParams.sort = firstSort.field;
                queryParams.order = firstSort.order;
              }
              // 处理筛选条件
              filters.forEach(({ field, values }) => {
                queryParams[field] = values.join(",");
              });
              queryParams.page = page.currentPage;
              queryParams.limit = page.pageSize;
              return userList(queryParams);
            },
            // 当点击工具栏删除按钮或者手动提交指令 delete 时会被触发
            delete: ({ body }) => {
              console.log(body);

              return;
            },
            // 当点击工具栏保存按钮或者手动提交指令 save 时会被触发
            save: ({ body }) => {
              console.log(body);
              return;
            },
            edit: ({ id }) => {
              console.log(id);
            },
          },
        },
        columns: [
          { type: "checkbox", title: "ID", },
          {
            field: "nickName",
            sortable: false,
            title: "名称",
          },
          {
            field: "phone",
            title: "手机号",
            sortable: false,
          },

          {
            field: "create_time",
            title: "创建时间",
            sortable: false,
            formatter({ cellValue }) {
              return XEUtils.toDateString(cellValue, "yyyy-MM-dd HH:ss:mm");
            },
          },
          {
            field: "update_time",
            title: "最后修改时间",
            sortable: false,
            visiable: false,
            formatter({ cellValue }) {
              return XEUtils.toDateString(cellValue, "yyyy-MM-dd HH:ss:mm");
            },
          },
          {
            field: "status",
            title: "用户状态",
            type: "html",
            slots: {
              default: "status_switch",
            },
          },
        ],
        importConfig: {
          remote: true,
          types: ["xlsx"],
          modes: ["insert"],
          // 自定义服务端导入
          importMethod({ file }) {
            const $grid = xGrid.value;
            const formBody = new FormData();
            formBody.append("file", file);
            return fetch({})
              .then((response) => response.json())
              .then((data) => {
                VXETable.modal.message({
                  content: `成功导入 ${data.result.insertRows} 条记录！`,
                  status: "success",
                });
                // 导入完成，刷新表格
                $grid.commitProxy("query");
              })
              .catch(() => {
                VXETable.modal.message({
                  content: "导入失败，请检查数据是否正确！",
                  status: "error",
                });
              });
          },
        },
        exportConfig: {
          remote: true,
          types: ["xlsx"],
          modes: ["current", "selected", "all"],
          // 自定义服务端导出
          exportMethod({ options }) {
            const $grid = xGrid.value;
            const proxyInfo = $grid.getProxyInfo();
            // 传给服务端的参数
            const body = {
              filename: options.filename,
              sheetName: options.sheetName,
              isHeader: options.isHeader,
              original: options.original,
              mode: options.mode,
              pager: proxyInfo ? proxyInfo.pager : null,
              ids:
                options.mode === "selected"
                  ? options.data.map((item) => item.id)
                  : [],
              fields: options.columns.map((column) => {
                return {
                  field: column.field,
                  title: column.title,
                };
              }),
            };
            // 开始服务端导出
            return fetch({ body })
              .then((response) => response.json())
              .then((data) => {
                if (data.id) {
                  VXETable.modal.message({
                    content: "导出成功，开始下载",
                    status: "success",
                  });
                  // 读取路径，请求文件
                  fetch({}).then((response) => {
                    response.blob().then((blob) => {
                      // 开始下载
                      VXETable.saveFile({
                        filename: "导出数据",
                        type: "xlsx",
                        content: blob,
                      });
                    });
                  });
                }
              })
              .catch(() => {
                VXETable.modal.message({
                  content: "导出失败！",
                  status: "error",
                });
              });
          },
        },
        checkboxConfig: {
          labelField: "id",
          reserve: true,
          highlight: true,
          range: true,
        },
      },
    };
  },
  methods: {

    change(e, row) {
      userUpdate({
        id: row.id,
        status: e.target.checked ? 1 : 0,
      })
        .then(() => {
          VXETable.modal.message({
            content: `操作成功`,
            status: "success",
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

  },
};
</script>
<style>
/* .vxe-table--header {
      background-color: green;
      color: #fff;
    } */
</style>
